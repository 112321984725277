
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































.duo {
  ::v-deep {
    .flexible-video {
      /* stylelint-disable-next-line declaration-no-important */
      padding-right: 0 !important;
      /* stylelint-disable-next-line declaration-no-important */
      padding-left: 0 !important;
    }

    .g-intro {
      max-width: 100%;
    }
  }

  @include mq(l) {
    display: flex;
  }
}

.duo__video {
  flex: 1 1 col(9, 12);

  @include mq($until: l) {
    margin: $spacing * 1.5 0;
  }
}

.duo__content {
  @include mq($until: l) {
    .duo__title {
      display: none;
    }
  }

  @include mq(l) {
    flex: 1 1 col(3, 12);
    margin-left: col(1, 12);
  }
}

.duo__title {
  // weird display of br?
  ::v-deep br {
    display: none;
  }

  @include mq(l) {
    display: none;

    .duo__content & {
      display: block;
    }
  }
}

.duo__text {
  margin-top: $spacing * 1.5;
}
